<template>
  <div id="container" class="single">
    <slot name="simple"></slot>
    <MpHamburger :style="{
      position: 'absolute',
      top: 'var(--padding)',
      right: '5%',
    }"/>
    <header>
      <h2>KUMON 算数</h2>
      <h2>学習アシストアプリ</h2>
      <h2>九九</h2>
    </header>
    <main>
      <ValidationObserver
        ref="validator"
        v-slot="{ /* eslint-disable */ errors /* eslint-enable */ }"
      >
        <form @submit.prevent="doLogin">
          <fieldset>
            <label for="loginId">ID</label>
            <ValidationProvider
              vid="loginId"
              name="ID"
              rules="required|max:100"
            >
              <input
                id="loginId"
                name="loginId"
                type="text"
                maxlength="100"
                v-model="credentials.login_id"
                rules="required"
                @input="reset"
              />
            </ValidationProvider>
          </fieldset>
          <fieldset>
            <label for="password">パスワード</label>
            <ValidationProvider
              vid="password"
              name="パスワード"
              rules="required"
            >
              <input
                id="password"
                name="password"
                type="password"
                maxlength="20"
                autocomplete="off"
                v-model="credentials.password"
                rules="required|max:100"
                @input="reset"
              />
            </ValidationProvider>
          </fieldset>
          <p class="message" :style="adjustErrorMessageStyle">{{ errorMessage ? errorMessage : "&nbsp;" }}</p>
          <button type="submit">ログイン</button>
        </form>
      </ValidationObserver>
    </main>
  </div>
</template>

<script>
import Restriction from "@/mixins/restriction";
import Activity from "@/mixins/activity";
import ServiceWorker from "@/mixins/serviceWorker";
import MpHamburger from "../ui/MpHamburger.vue";
export default {
    mixins: [Restriction, Activity, ServiceWorker],
    data: function () {
        return {
            credentials: {
                login_id: "",
                password: "",
                subject: 1,
            },
            errorMessage: "",
        };
    },
    computed: {
        errors() {
            return this.$refs.validator.errors;
        },
        adjustErrorMessageStyle() {
            if (this.errorMessage.length > 20) {
                return {
                    fontSize: "1.3rem",
                };
            }

            return {};
        },
    },
    created() {
      // 横レイアウト強制
      this.$log.debug("横レイアウト強制");
      this.$store.commit('auth/setIsUnrestricted',0);
    },
    methods: {
        doLogin() {

            // バリデーション実行
            this.$log.debug("バリデーション実行！！");
            this.$refs.validator.validate().then((success) => {
                this.$log.debug("バリデーション結果=>%s", success);
                if (success) {
                    if (this.isOnline()) {
                        // ログイン認証
                        this.$log.debug("【起動とログイン】ログイン認証=>%s", JSON.stringify(this.credentials));
                        this.$store
                            .dispatch("auth/login", this.credentials)
                            .then(() => {
                            // 認証成功
                            this.$log.debug("【起動とログイン】認証成功");
                            // 前回ログインID取得
                            var prevLoginId = this.$store.getters["auth/getLoginId"];
                            this.$log.debug("【起動とログイン】前回ログインID取得=>%s", prevLoginId);
                            if (this.credentials.login_id !== prevLoginId) {
                                // 異なるユーザ
                                this.$log.debug("【起動とログイン】異なるユーザ");
                                // 学習状況最新化
                                this.$log.debug("【起動とログイン】学習状況最新化=>%s", prevLoginId);
                                this.$store
                                    .dispatch("learningState/refresh", prevLoginId)
                                    .then(() => {
                                    // 学習状況最新化成功
                                    this.$log.debug("【起動とログイン】学習状況最新化成功=>%s", prevLoginId);
                                    // 学習状況送信
                                    this.$log.debug("【起動とログイン】ローカル学習状況送信=>%s", prevLoginId);
                                    this.$store.dispatch("learningState/post", prevLoginId);
                                })
                                    .finally(() => {
                                    // 学習履歴送信
                                    this.$log.debug("【起動とログイン】学習履歴送信=>%s", prevLoginId);
                                    this.$store
                                        .dispatch("history/post", prevLoginId)
                                        .finally(() => {
                                        // 認証情報、学習状況、学習履歴、録音データクリア
                                        this.$log.debug("【起動とログイン】認証情報クリア=>%s", prevLoginId);
                                        this.$store.commit("auth/resetCredentials");
                                        this.$store.commit("auth/resetLoginDateTime");
                                        this.$log.debug("【起動とログイン】学習状況初期化=>%s", prevLoginId);
                                        this.$store.commit("learningState/init");
                                        this.$log.debug("【起動とログイン】学習履歴クリア=>%s", prevLoginId);
                                        this.$store.commit("history/clearHistory");
                                        this.$log.debug("【起動とログイン】録音データ初期化=>%s", prevLoginId);
                                        this.$store.commit("recorded/init");
                                        // 画面ボタン操作イベント
                                        this.$store.dispatch("history/viewButtonEvent", {
                                            loginId: this.credentials.login_id,
                                            view: this.$store.getters["app/getCurrentView"],
                                            event: "ログイン",
                                        });
                                        // ログインID、パスワード、ログイン日時保存
                                        this.$store.commit("auth/setCredentials", this.credentials);
                                        this.$store.commit("auth/setLoginDateTime", Date.now());
                                        // 学習状況最新化
                                        this.$log.debug("【起動とログイン】学習状況最新化");
                                        this.$store
                                            .dispatch("learningState/refresh")
                                            .then(() => {
                                            // 学習状況最新化成功
                                            this.$log.debug("【起動とログイン】学習状況最新化成功");
                                            // 学習状況送信
                                            this.$log.debug("【起動とログイン】ローカル学習状況送信");
                                            this.$store.dispatch("learningState/post");
                                            // 学習履歴送信
                                            this.$log.debug("【起動とログイン】学習履歴送信");
                                            this.$store.dispatch("history/post");
                                            // アプリケーション開始
                                            this.startApp();
                                        })
                                            .catch(() => {
                                            // NGまたはオフライン
                                            this.$log.debug("【起動とログイン】学習状況最新化NGまたはオフライ");
                                            // アプリケーション開始
                                            this.startApp();
                                        });
                                    });
                                });
                            }
                            else {
                                // 画面ボタン操作イベント
                                this.$store.dispatch("history/viewButtonEvent", {
                                    loginId: this.credentials.login_id,
                                    view: this.$store.getters["app/getCurrentView"],
                                    event: "ログイン",
                                });
                                // ログインID、パスワード、ログイン日時保存
                                this.$store.commit("auth/setCredentials", this.credentials);
                                this.$store.commit("auth/setLoginDateTime", Date.now());
                                // 学習状況最新化
                                this.$log.debug("【起動とログイン】学習状況最新化");
                                this.$store
                                    .dispatch("learningState/refresh")
                                    .then(() => {
                                    // 学習状況最新化成功
                                    this.$log.debug("【起動とログイン】学習状況最新化成功");
                                    // 学習状況送信
                                    this.$log.debug("【起動とログイン】ローカル学習状況送信");
                                    this.$store.dispatch("learningState/post");
                                    // 学習履歴送信
                                    this.$log.debug("【起動とログイン】学習履歴送信");
                                    this.$store.dispatch("history/post");
                                    // アプリケーション開始
                                    this.startApp();
                                })
                                    .catch(() => {
                                    // NGまたはオフライン
                                    this.$log.debug("【起動とログイン】学習状況最新化NGまたはオフライ");
                                    // アプリケーション開始
                                    this.startApp();
                                });
                            }
                        })
                            .catch((e) => {
                              if (e.response && e.response.status === 403) {
                                this.$log.debug("【起動とログイン】アクセス禁止");
                                this.errorMessage = e.response.data.error;
                              } else {
                                this.$log.debug("【起動とログイン】認証失敗");
                                this.errorMessage = "IDまたはパスワードが違います";
                              }
                        });
                    }
                    else {
                        // オフラインの場合
                        this.$log.debug("【起動とログイン】オフライン");
                        this.errorMessage = "ネットワークに接続した状態でタップしてください";
                    }
                }
                // バリデーションエラーが存在する場合
                if (this.errors.loginId.length > 0) {
                    this.errorMessage = this.errors.loginId[0];
                }
                if (this.errors.password.length > 0) {
                    this.errorMessage = this.errors.password[0];
                }
            });
        },
        startApp() {
            // アプリケーション更新
            this.appUpdate();
        },
        reset() {
            this.errorMessage = "";
        },
    },
    components: { MpHamburger }
};
</script>